<template>
  <div>
    <page-title :title="`Bond Templates`">
      <template slot="button">
        <router-link
          :to="{ name: 'bond-templates.create' }"
          class="btn btn-primary btn-sm float-right" style="color:white !important"
        >
          <i class="lni-plus mr-2"></i> Add Bond Template
        </router-link>
      </template>
    </page-title>
    <table class="table  table-striped bg-white">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(template, i) in templates" :key="`template-${i}`">
          <td>{{ i + 1 }}</td>
          <td>{{ template.name }}</td>
          <td>{{ template.type }}</td>
          <td>
            <router-link
              :to="{ name: 'bond-templates.show', params: { id: template.id } }"
              class="btn btn-sm btn-info"
            >
              <i class="lni-eye mr-2"></i> view
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: "bond-templates.index",

  mounted() {
    this.$loader.start()

    this.$axios
      .get("/api/v1/dashboard/bond-templates")
      .then((response) => {
        this.templates = response.data;
        setTimeout(() => {
          this.$loader.stop()
          $('table').DataTable()
        }, 1000)
      })
      .catch((error) => {
        this.$http(error.response);
      });
  },

  data() {
    return {
      templates: [],
    };
  },
};
</script>
